/**
 * 
 * @property State
 */
const State = Object.freeze({
    TEXT: 0,
    ESCAPE: 1,
    SKIP_FORMAT: 2,
    PARAGRAPH1: 3,
    PARAGRAPH2: 4,
    PARAGRAPH3: 5
});

const EntityType = Object.freeze({
    TEXT: 0,
    SCOPE: 1,
    PARAGRAPH: 2,
    NON_BREAKING_SPACE: 3,
    PARAGRAPH_ALIGNMENT: 4
});

const shortFormats = new Set(['L', 'l', 'O', 'o', 'K', 'k', 'P', 'X', '~']);
const longFormats = new Set(['f', 'F', 'p', 'Q', 'H', 'W', 'S', 'A', 'C', 'T']);
const validEscapes = new Set(['\\', '{', '}']);

export class MTextFormatParser {
    constructor() {
        this.entities = [];
    }

    Parse(text, fontSize = 16, fontFamily = 'Arial') {
        const n = text.length;
        let textStart = 0;
        let state = State.TEXT;
        let scopeStack = [];
        let curEntities = this.entities;
        let curPos = 0;
        let nextCharPosition = 0;

        const _this = this;

        function EmitText() {
            if (state !== State.TEXT || textStart === curPos) {
                return;
            }
            curEntities.push({
                type: EntityType.TEXT,
                content: text.slice(textStart, curPos)
            });
            textStart = curPos;
        }

        function EmitEntity(type) {
            curEntities.push({ type: type });
        }

        function PushScope() {
            const scope = {
                type: EntityType.SCOPE,
                content: []
            };
            curEntities.push(scope);
            curEntities = scope.content;
            scopeStack.push(scope);
        }

        function PopScope() {
            if (scopeStack.length === 0) {
                return;
            }
            scopeStack.pop();
            curEntities = scopeStack.length === 0 ? _this.entities : scopeStack[scopeStack.length - 1].content;
        }

        for (; curPos < n; curPos++) {
            const c = text.charAt(curPos);

            switch (state) {
                case State.TEXT:
                    if (c === '{' || c === '}') {
                        EmitText();
                        state = c === '{' ? State.ESCAPE : State.TEXT;
                        textStart = curPos + 1;
                        nextCharPosition += measureCharSpacing(c, fontSize, fontFamily);
                        continue;
                    }
                    if (c === '\\') {
                        EmitText();
                        state = State.ESCAPE;
                        continue;
                    }
                    nextCharPosition += measureCharSpacing(c, fontSize, fontFamily);
                    continue;

                case State.ESCAPE:
                    if (shortFormats.has(c)) {
                        EmitEntity(c === 'P' ? EntityType.PARAGRAPH : EntityType.NON_BREAKING_SPACE);
                        state = State.TEXT;
                        textStart = curPos + 1;
                        nextCharPosition += measureCharSpacing(c, fontSize, fontFamily);
                        continue;
                    }
                    if (c === 'U' && curPos + 2 < n && text.charAt(curPos + 1) === '+') {
                        let endPos = text.indexOf(';', curPos + 2);
                        if (endPos === -1) endPos = curPos + 6;
                        let unicodeCode = text.substring(curPos + 2, endPos);
                        let char = String.fromCodePoint(parseInt(unicodeCode, 16));
                        curEntities.push({ type: EntityType.TEXT, content: char });
                        curPos = endPos + (text.charAt(endPos) === ';' ? 1 : 0);
                        state = State.TEXT;
                        textStart = curPos;
                        nextCharPosition += measureCharSpacing(char, fontSize, fontFamily);
                        continue;
                    }
                    if (longFormats.has(c)) {
                        state = State.SKIP_FORMAT;
                        continue;
                    }
                    if (validEscapes.has(c)) {
                        textStart = curPos;
                    } else {
                        textStart = curPos - 1;
                    }
                    state = State.TEXT;
                    continue;

                case State.SKIP_FORMAT:
                    if (c === ';') {
                        textStart = curPos + 1;
                        state = State.TEXT;
                    }
                    continue;
            }
        }

        function measureCharSpacing(char, fontSize = 16, fontFamily = 'Arial') {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            context.font = `${fontSize}px ${fontFamily}`;

            let charWidth = context.measureText(char).width;

            const isUpperCase = char === char.toUpperCase() && char !== char.toLowerCase();

            const spacing = isUpperCase ? 8 : 5; 
            return charWidth + spacing;
        }


        EmitText();
        return this;
    }

    GetContent() {
        return this.entities;
    }

    *GetText() {
        function* TraverseItems(items) {
            for (const item of items) {
                if (item.type === EntityType.TEXT) {
                    yield item.content;
                } else if (item.type === EntityType.SCOPE) {
                    yield* TraverseItems(item.content);
                }
            }
        }

        yield* TraverseItems(this.GetContent());
    }
}

MTextFormatParser.EntityType = EntityType;


MTextFormatParser.EntityType = EntityType;